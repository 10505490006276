<template>
  <div class="ACE2">
    <div class="banner">
      <div class="title">ACE2</div>
      <div class="osDMS">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="Display">
      <div class="main">
        <div class="title">Main Display</div>
        <div class="text">15.6"1920 x 1080</div>
        <div class="text">Capacitive Multi-Touch Screen</div>
      </div>
      <div class="secondary">
        <div class="title">Secondary Display</div>
        <div class="text textLeft">10.1"1280x800(optional)</div>
        <div class="text textLeft">10.1"1024x600(optional)</div>
      </div>
      <div class="leftLine">
        <img src="../../../assets/ACE 2/左.png" alt="" />
      </div>
      <div class="rightLine">
        <img src="../../../assets/ACE 2/右.png" alt="" />
      </div>
    </div>
    <div class="Three-axis">
      <div class="title">Three-axis foldable design</div>
      <div class="img">
        <img src="../../../assets/ACE 2/第三页配图.png" alt="" />
      </div>
    </div>
    <div class="ACE2con">
      <div class="titleBox">
        <div class="title">ACE 2</div>
      </div>
      <div class="itemBox">
        <div class="one">
          <div class="itemA">
            <div class="title">OS</div>
            <div class="text">Windows 11/10</div>
          </div>
          <div class="itemA">
            <div class="title">Memory</div>
            <div class="text">RAM 4/8/16GB DDR</div>
            <div class="text">ROM 128/256/512GB SSD</div>
          </div>
          <div class="itemA">
            <div class="title">CPU</div>
            <div class="text">
              Intel Celeron J6412 ，Core 4th/8th/11th series
            </div>
          </div>
          <div class="itemA">
            <div class="title">Ethernet</div>
            <div class="text">TCP/IP 1000M</div>
          </div>
        </div>
        <div class="two">
          <div class="left">
            <div class="itemA">
              <div class="title">Main Display</div>
              <div class="text">15.6” FHD 1920×1080</div>
              <div class="text">Capacitive Multi-Touch Screen</div>
            </div>
            <div class="itemA">
              <div class="title">2nd Display</div>
              <div class="text">10.1” 1280×800（optional）</div>
              <div class="text">10.1” 1024×600（optional）</div>
            </div>
            <div class="itemA">
              <div class="title">Optional</div>
              <div class="text">Face Recognition Camera</div>
              <div class="text">MSR</div>
              <div class="text">NFC</div>
            </div>
          </div>
          <div class="right">
            <div class="itemC">
              <div class="top">
                <div class="title">Dimension</div>
                <div class="text">366mm*209mm*310mm</div>
              </div>
              <div class="bottomImg">
                <div class="left textsmall">310mm</div>
                <div class="top textsmall">366mm</div>
                <div class="bottom textsmall">209mm</div>
                <div></div>
                <div></div>
                <div class="img">
                  <img src="../../../assets/ACE 2/ACE 2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="one">
          <div class="itemA">
            <div class="title">Display Interface</div>
            <div class="text">1 x HDMI/VGA</div>
          </div>
          <div class="itemA">
            <div class="title">Power Interface</div>
            <div class="text">1 x φ5.5mm DC Jack</div>
          </div>
          <div class="itemA">
            <div class="title">Audio</div>
            <div class="text">2 x Speaker (2W)</div>
          </div>
          <div class="itemA">
            <div class="title">Wireless PAN</div>
            <div class="text">BT4.2 + BLE</div>
          </div>
          <div class="itemA">
            <div class="title">WiFi</div>
            <div class="text">2.4G/5GHz dual-band Wi-Fi</div>
            <div class="text">IEEE 802.11 a/b/g/n</div>
          </div>
          <div class="itemA">
            <div class="title">Notification</div>
            <div class="text">Tone</div>
            <div class="text">Power indicator</div>
          </div>
          <div class="itemD">
            <div class="title">Environment</div>
            <div class="text">Operating Temp：0℃ ～+40℃</div>
            <div class="text">Storage Temp：-20℃ ~ +60℃</div>
            <div class="text">Huminity：5% ~ 95%RH</div>
            <div class="text">(No condensation)</div>
          </div>
          <div class="itemD">
            <div class="title">Interface</div>
            <div class="text">6 x USB</div>
            <div class="text">1 x DB9</div>
            <div class="text">1 x 3.5mm Headphone jack</div>
            <div class="text">1 x 3.5mm Microphone interface</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.ACE2 {
  .banner {
    padding-top: 23px;
    height: 278px;
    background: url('../../../assets/ACE 2/首页.png') no-repeat center;
    background-size: cover;
    .title {
      line-height: 37.65px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
    }
    .osDMS {
      margin: 0px auto 0px;
      width: 241px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 114px;
        height: 22.14px;
        background-clip: padding-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .Display {
    position: relative;
    height: 278px;
    background: url('../../../assets/ACE 2/第二页.png') no-repeat center;
    background-size: cover;
    .title {
      font-weight: 700;
      color: rgba(0, 92, 255, 1);
      font-size: 12px;
      line-height: 17.38px;
    }
    .textLeft {
      text-align: end;
    }
    .text {
      color: rgba(255, 255, 255, 1);
      font-size: 10px;
      line-height: 14.48px;
    }
    .main {
      position: absolute;
      top: 39px;
      left: 37px;
    }
    .secondary {
      position: absolute;
      top: 39px;
      right: 35px;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .leftLine {
      position: absolute;
      width: 73px;
      height: 66px;
      top: 87px;
      left: 48px;
    }
    .rightLine {
      position: absolute;
      width: 98px;
      height: 58px;
      top: 85px;
      right: 51px;
    }
  }
  .Three-axis {
    background: rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      color: rgba(255, 255, 255, 1);
      padding: 18px 0px 9px 0px;
      font-size: 16px;
      font-weight: 700;
      line-height: 23.17px;
    }
    .img {
      border-radius: 10px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 290;
      height: 163px;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .ACE2con {
    background: rgba(0, 0, 0, 1);
    .titleBox {
      margin-left: 30px;
      padding: 35px 0px 9px 0px;
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 20px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 53px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 13px;
      padding: 0px 20px;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 10px;
        font-weight: 500;
        line-height: 14.48px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 349px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        position: relative;
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 258px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(0, 92, 255, 0.5);
        .text {
          font-size: 10px;
          color: rgba(255, 255, 255, 1);
        }
        .bottomImg {
          color: rgba(255, 255, 255, 1);
          font-size: 8px;
          font-weight: 500;
          position: absolute;
          top: 89.8px;
          left: 7.2px;
          width: 154px;
          height: 117.35px;
          .textsmall {
            font-size: 8px;
          }
          .img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .top {
            position: absolute;
            top: -3px;
            left: 70px;
          }
          .left {
            position: absolute;
            bottom: 60px;
            left: -3px;
          }
          .bottom {
            position: absolute;
            bottom: 2px;
            left: 70px;
          }
        }
      }
      .itemD {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 120px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .one {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
      .two {
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .three {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
    }
  }
}
</style>
